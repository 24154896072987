import React, { useLayoutEffect } from 'react';
import { IonContent, IonIcon, IonPage } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import DeliveryForm from '../components/cart/DeliveryForm';
import MorganaDeliveryForm from '../components/cart/MorganaDeliveryForm';

function DeliveryOrderPage() {
    const history = useHistory();
    const { type, id } = useParams();

    const { t } = useTranslation();

    const onBackHandler = () => history.push(`/${type}/q/${id}/orders/type`);

    // useLayoutEffect(() => {
    //     async function pixelLog() {
    //         try {
    //             await window.fbq('track', 'ViewContent');
    //         } catch (e) {
    //             console.log({ pixelError: e })
    //         }
    //     }

    //     pixelLog();
    // }, [])

    return (
        <IonPage>
            <IonContent>
                <section id="takeaway-page" className="relative pt-6 font-sans">
                    <div className="flex items-center border-b border-b-grey-200">
                        <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <h2 className="pb-4 w-full text-center font-bold text-2xl">{process.env.REACT_APP_NAMESPACE == "shop" ? "Consegna in città" : "Delivery"}</h2>
                    </div>

                    {/* {"morgana" === process.env.REACT_APP_NAMESPACE ? <MorganaDeliveryForm /> : <DeliveryForm />} */}
                    <DeliveryForm />

                </section>
            </IonContent>
        </IonPage>)
}

export default DeliveryOrderPage;

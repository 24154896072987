import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import Reviews from './Reviews';
import formats from '../../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';
import CouponModalTrigger from '../modals/CouponModalTrigger';
import features from '../../utils/features';
import client from '../../logic/cartSharing';
import forms from '../../utils/forms';

function CartSharingGuest() {
    const history = useHistory();
    const { type, id } = useParams();

    const { products, quantity, total } = useCart();

    const { t } = useTranslation();

    const [coupon, setCoupon] = useState();
    const [newtotal, setNewTotal] = useState();
    const [couponSentinel, setCouponSentinel] = useState();

    const urlParams = new URLSearchParams(window.location.search);
    const [shareId] = useState(urlParams.get("shareid"));

    const onBackHandler = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let shareid = urlParams.get("shareid");
        if (shareid) {
            history.push(`/${type}/q/${id}/menu?shareid=${shareid}`)
        } else {
            history.push(`/${type}/q/${id}/menu`)
        };
    }

    // useLayoutEffect(() => {
    //     async function pixelLog() {
    //         try {
    //             await window.fbq('track', 'ViewContent');
    //         } catch (e) {
    //             console.log({ pixelError: e })
    //         }
    //     }

    //     pixelLog();
    // }, [])

    function getUserId() {
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            const newUserId = forms.generateRandomID("");
            sessionStorage.setItem("user_id", newUserId);
            return newUserId;
        }
        return userId;
    };

    useEffect(() => {
        function checkCoupon() {
            let coupon = sessionStorage.getItem("coupon");
            if (coupon) {
                coupon = JSON.parse(coupon);
                console.log(coupon);
                setCoupon(coupon);
                setNewTotal(formats.formatPercentageToPrice(coupon.discount, total));
            }
        }

        checkCoupon();
    }, [total, couponSentinel]);

    // Guest -> Host
    async function onShareHandler() {
        const name = window.prompt("Come ti chiami ?");
        const payload = {
            resId: id,
            type: type,
            order: [{
                user: name,
                user_id: getUserId(),
                products: products,
            }],
            action: "send active",
            token: shareId,
        }
        const res = await client.doPut(payload);
        history.push(`/${type}/q/${id}/orders/sharesuccess`);
    }

    return (
        <div className="cart-sharing-passive pb-48">
            <div className="flex items-center border-b border-b-grey-200">
                <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>

                <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("cart.h2_1")}</h2>
            </div>

            <p className="p-4 px-10 text-center">Stai utilizzando il carrello condiviso, termina la condivisione premendo "condividi menu".</p>

            <Reviews products={products} />

            <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 bg-white">

                {coupon && coupon.accepted == true ?
                    <p className="flex flex-col px-4 py-3">
                        <span>Totale: {formats.formatPrice(total)}</span>
                        <span>Valore coupon: {coupon.discount}%</span>
                        <span className="text-mycia-green">Totale scontato: <span className="font-bold">{formats.formatPrice(newtotal)}</span></span>
                    </p>
                    :
                    <p className="flex justify-between px-4 py-3"><span>{t("cart.p_1.span_1")}</span> <span className="font-bold">{formats.formatPrice(total)}</span></p>
                }


                <button type="button" onClick={onShareHandler} className="block py-2 rounded-full bg-mycia-green">Condividi il menu</button>


                {features.toggler().includeCoupon() ? type != "r" ? <CouponModalTrigger setCouponSentinel={setCouponSentinel} /> : null : null}

            </div>
        </div>
    );
}

export default CartSharingGuest;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import features from '../../utils/features';
import './DeliveryPopupModalContentStyle.css';
import coupons from '../../logic/coupons';

function CouponModalContent(props) {
    const { onClickHandler, setCouponSentinel } = props;

    const [coupon, setCoupon] = useState();
    const [acceptedCoupon, setAcceptedCoupon] = useState();

    const { t } = useTranslation();

    function checkHandler() {
        const res = coupons.getCoupon(coupon);
        console.log(res);
        if (res.accepted == true) {
            setAcceptedCoupon(true);
            sessionStorage.setItem('coupon', JSON.stringify(res));
            setCouponSentinel(true);
            onClickHandler();
        } else {
            setAcceptedCoupon(false);
        }
    }

    return (
        <div className="coupon-modal-content overflow-auto text-black ">
            <div className="px-3 py-8 text-center">
                <p>Hai un codice sconto ? Inseriscilo qui sotto</p>
                <input onChange={(e) => setCoupon(e.target.value)} placeholder="codice sconto" className="w-2/3 m-1 p-2 rounded-lg bg-grey-200" />
                <button onClick={checkHandler} className="p-2 border border-mycia-green rounded-lg">Applica</button>
                {acceptedCoupon == false ? <p className="text-red-600">Il codice non è stato accettato</p> : null}
            </div>
            <div className="fixed bottom-0 py-2 bg-mycia-green rounded-b-lg w-full">
                <button className="w-full" onClick={onClickHandler}>Non ho un codice sconto</button>
            </div>
        </div>
    )
}

export default CouponModalContent;

import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from '../utils/contexts/tracker-context';
import RestaurantLoader from '../components/containers/RestaurantLoader';
import Splash from '../components/UI/Splash';
import LanguageSelector from '../components/LanguageSelector';
import features from '../utils/features';
import theme from '../utils/theme';

function SplashPage(props) {
    const { trackDataFn } = useTracker();

    const [palette, setPalette] = useState("");

    const { type, id } = useParams();

    useLayoutEffect(() => {
        if (!process.env.REACT_APP_THEME) {
            theme.getTheme(id, setPalette);
        } else {
            setPalette(process.env.REACT_APP_THEME)
        }

        async function track() {
            await trackDataFn(id, "usersession", { "type": type });
        }

        track();
    }, []);

    return (
        <IonPage>
            <IonContent style={theme.getThemeIonComponents(palette).splashPage}>
                <section id="splash-page" className="font-sans">
                    <RestaurantLoader component={Splash} {...props} />
                </section>
                {features.toggler().includeLanguageSelector() && <LanguageSelector onMenu={false} />}
            </IonContent>
        </IonPage>
    );
}

export default SplashPage;

import React from 'react';
import AllergenIcons from '../UI/AllergenIcons';
import DishModalTrigger from '../modals/DishModalTrigger';
import TranslationModalTrigger from '../modals/TranslationModalTrigger';
import assets from '../../utils/assets';
import dl from '../../utils/device-language';
import formats from '../../utils/formats';
import features from '../../utils/features';

function Details(props) {
    const { dish, showDishModal, setShowDishModal, onDetailsOpenHandler } = props;

    let icons = [];
    let description = formats.formatDescription(dish);

    (dish.has_sulfites) && icons.push(assets.assetsPath("images/icons/allergie/solfiti.png"));
    (dish.is_frozen) && icons.push(assets.assetsPath("images/icons/allergie/surgelati.png"));
    for (const ingredient of dish.ingredients) {
        ingredient.has_allergens && ingredient.allergens.map((allergen, i) => {
            let path = assets.assetsPath(allergen.image_url);
            !icons.includes(path) && icons.push(path);
            return [];
        });
    }

    return (
        <div className="shop-item-details py-1">
            {/* {description.length && (dl.isCleanDeviceLanguage("it-IT") || dl.isCleanDeviceLanguage("it")) ?
                <p className="mb-2 text-sm">{description}</p>
                : null
            } */}

            <div className="flex flex-wrap items-center pt-2">
                <AllergenIcons icons={icons} />
                {formats.shouldDisplayDishDetails(dish) ? <DishModalTrigger dish={dish} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} /> : null}
            </div>

            {(!(dl.isDeviceLanguage("it") || dl.isDeviceLanguage("it-IT")) && features.toggler().includeSuggestions()) && <TranslationModalTrigger type="dish" dish={dish} />}
        </div>
    );
}

export default Details;

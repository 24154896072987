import axios from 'axios';
import qs from 'qs';
import formats from '../utils/formats';

async function doSendMessage(payload, customMessage) {
    function buildDefaultMessage() {
        const { hours, minutes } = formats.shiftTime(new Date(payload.order.timestamp), 0);
        const defaultMessage = `Hai ricevuto un ordine per ${payload.order.type} alle ore ${hours}:${minutes} da ${payload.order.customer.name} ${payload.order.customer.surname}, all'indirizzo ${payload.order.customer.street}:\n${payload.order.products.map(product => `\n${product.name} [x${product.quantity}]`)}\n\ntotale ordine: ${payload.order.total}€\n\nPer accettare l'ordine puoi farlo direttamente dalla dashboard(https://orders.mycontactlessmenu.mycia.it/s/${payload.restaurant.token}) in tua dotazione. Altrimenti scrivendo direttamente al numero del cliente: ${payload.order.customer.phone}.\n\nGrazie,\nMyCIA.`;
        return defaultMessage;
    }

    var data = qs.stringify({
        'To': `whatsapp:+39${payload.order.customer.phone}`,
        'From': 'whatsapp:+14155238886',
        'Body': customMessage ? customMessage : buildDefaultMessage(),
    });

    var config = {
        method: 'post',
        url: 'https://api.twilio.com/2010-04-01/Accounts/AC06d961f038145b71a1c11a7db7b82b2c/Messages.json',
        headers: {
            'Authorization': 'Basic QUMwNmQ5NjFmMDM4MTQ1YjcxYTFjMTFhN2RiN2I4MmIyYzo5YmZiNGIxOTgyMmFkYTVkZWU5MWJhMGQ0ODE2Y2YyMQ==',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
    };

    axios(config)
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.log({ errorino: error });
        });

}

function doBuildMessage(number, payload) {
    const formattedNumber = number.replace("+39", "");

    const message = `
Ciao! Ecco il mio ordine:
                    
🍝 Ordine
${payload.order.products.map(p =>
        `${p.name}(${p.quantity} pz)`
    )}

Totale ordine: ${payload.order.total}€

👤 Nome
${payload.order.customer.name} ${payload.order.customer.surname} 

📱 Telefono
${payload.order.customer.phone}

${payload.order.customer.street ?
            `📍 Indirizzo
${payload.order.customer.street}
https://maps.google.com?q=${encodeURI(payload.order.customer.street)}`
            : ``}

🕑 Orario richiesto
${payload.order.customer.time}

📝 Note
Tipo ordine: ${payload.order.type}
Note ordine: ${payload.order.order_note}
`
    return `https://wa.me/39${formattedNumber}?text=${encodeURI(message)}`;
}

export default {
    doSendMessage,
    doBuildMessage,
}

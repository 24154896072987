function getThemeIonComponents(theme) {
    if (theme == "cocacola")
        return {
            splashPage: { "--background": "#e6e6e5" },
            menupage: { "--background": "#e6e6e5" },
        };
    if (theme == "morgana") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    }
    if (theme == "sushitao") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    }
    if (theme == "redcafe") {
        return {
            splashPage: { "--background": "#EEE4D2" },
            menupage: { "--background": "#EEE4D2" },
        };
    }
    if (theme == "motueka") {
        return {
            splashPage: { "--background": "#383839" },
            menupage: { "--background": "#383839" },
        };
    }
    if (theme == "camparino") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    if (theme == "voglia_argentina") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    }
    else return {
        menupage: null
    }
}

function getTheme(id, setterPaletteState) {
    if (id == "9440") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("motueka");
        setterPaletteState("motueka")
    }
    if (id == "6433") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("camparino");
        setterPaletteState("camparino")
    }
    if (id == "9506") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("voglia_argentina");
        setterPaletteState("voglia_argentina")
    }
    if (id == "9207") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("morgana");
        setterPaletteState("morgana")
    }
    if (id == "9521") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("il-cacciatore");
        setterPaletteState("il-cacciatore");
    }
    if (id == "9520") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("pane-vino");
        setterPaletteState("pane-vino");
    }
    if (id == "9522") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mela-oro");
        setterPaletteState("mela-oro");
    }
}

export default {
    getThemeIonComponents,
    getTheme,
}

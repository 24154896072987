import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useTranslation } from 'react-i18next';
import Wisher from './Wisher';
import formats from '../../utils/formats';
import DrinkVariations from './DrinkVariations';
import DishVariations from './DishVariations';
import DishAdditions from './DishAdditions';
import BetterWith from './BetterWith';

function Item(props) {
    const { dish, closerHandler } = props;
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();

    const [hasDrinkVariations, setHasDrinkVariations] = useState(false);
    const [hasDishVariations, setHasDishVariations] = useState(false);
    const [hasDishAdditions, sethasDishAdditions] = useState(false);
    const [drinkChanged, setDrinkChanged] = useState(false);
    const [dishChanged, setDishChanged] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [buyedSuggestedDrink, setBuyedSuggestedDrink] = useState(false);

    const { t } = useTranslation();

    const { add } = useCart();
    const [note, addNote] = useState("");

    const changeHandler = ({ target }) => addNote(target.value);

    const addProduct = () => {
        async function track() {
            await trackDataFn(id, "addproduct", { "type": type, "product": dish.id, "price": dish.price, "name": dish.name, is_drink: dish.hasOwnProperty("cellar") });
            // try {
            //     await window.fbq('track', 'AddToCart');
            // } catch (e) {
            //     console.log({ pixelError: e })
            // }
        }

        dish.note = note;

        add(dish, quantity);

        if (buyedSuggestedDrink == true) {
            add(dish.suggested_drink, 1);
        }

        track();

        delete dish.use_glass_price;

        closerHandler();
    };

    useEffect(() => {
        if (dish.glass_price && dish.glass_price != 0) {
            setHasDrinkVariations(true);
        }
        if (dish.variations && dish.variations.length != 0) {
            setHasDishVariations(true);
        }

        if (dish.additions && dish.additions.length != 0) {
            sethasDishAdditions(true);
        }
    })

    return (
        <div className="pb-10 overflow-y-scroll">
            <div className="item relative overflow-y-scroll flex flex-col justify-between bg-white">
                <div className="flex flex-col py-4 border-b border-b-grey-200">
                    <div className="flex items-center px-4 pb-4 border-b border-b-grey-200">
                        <p className="flex-1 text-xl">{formats.capitalize(formats.truncate(dish.name, 20))}</p>
                        <p className="flex-none font-bold">{formats.formatPrice(dish.price)}</p>
                    </div>

                    <div className="px-3 py-1">
                        <img className="object-contain h-48 w-full" src={dish.image_path}></img>
                    </div>

                    <div className="text-center p-4">
                        <p className="text-xl">{t("item.p_1")}</p>
                        <Wisher onQuantityHandler={setQuantity} />
                    </div>
                    <input type="text" name="note" className="mt-2 py-2 mx-8 rounded-lg text-center bg-grey-200" placeholder="Aggiungi nota" onChange={changeHandler} />

                    {dish.suggested_drink != undefined && dish.suggested_drink != "" && <BetterWith product={dish} buyedSuggestedDrink={buyedSuggestedDrink} setBuyedSuggestedDrink={setBuyedSuggestedDrink} />}

                    {hasDrinkVariations && <DrinkVariations product={dish} changed={drinkChanged} setChanged={setDrinkChanged} />}
                    {hasDishVariations && <DishVariations product={dish} changed={dishChanged} setChanged={setDishChanged} />}
                    {hasDishAdditions && <DishAdditions product={dish} />}

                </div>
            </div>
            <div className="flex flex-col p-4 bg-white">
                <button type="button" onClick={addProduct} className="add-cart-item block py-2 rounded-full bg-mycia-green">{t("item.button_1")}</button>
                <button type="button" onClick={closerHandler} className="block mt-2 py-2 rounded-full underline">{t("item.button_2")}</button>
            </div>
        </div>
    );
}

export default Item;

import React, { useState } from 'react';
import { useParams } from 'react-router';
import Cta from '../cart/Cta';
import Details from '../item/Details';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import assets from '../../utils/assets';
import { useTracker } from '../../utils/contexts/tracker-context';

function Product(props) {
    const { product } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();
    const { trackDataFn } = useTracker();

    const [showDishModal, setShowDishModal] = useState(false);

    async function onDetailsOpenHandler() {
        await trackDataFn(restaurant.id, "dishview", { "type": type, "dish": product.id, "name": product.name, "price": product.price });

        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (e) {
        //     console.log({ pixelError: e })
        // } finally {
        setShowDishModal(true);
        // }
    };

    return (
        <div className="shop-product p-2">
            {product.special && <div className="text-xs bg-yellow-500 rounded-t-lg text-center w-1/2">PRODOTTO CONSIGLIATO</div>}

            <div className="shop-product-content flex flex-col h-full justify-between rounded-lg shadow bg-grey-100">
                <div>
                    <div className="p-2">
                        {product.image_path != "" ? <img className="m-auto h-40 align-center rounded-lg" onClick={onDetailsOpenHandler} src={product.image_path}></img>
                            :
                            <img className="object-scale-down w-40 h-40 align-center rounded-lg" onClick={onDetailsOpenHandler} src={assets.assetsPath("images/shop-item.png")}></img>
                        }

                        <p className="text-sm pt-2 font-semibold">{formats.capitalize(product.name)}</p>

                        {formats.shouldDisplayFilterIcon(product) ? <img className="inline-block pl-1 w-5 mr-2" src={assets.assetsPath(product.icon)} /> : null}

                        <p className="text-sm text-mycia-red">{product.other_user_suggestion}</p>

                        <Details dish={product} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} />

                        {formats.cleanPrice(product.price) !== "" ? <p className="py-1 font-bold">{formats.formatPrice(product.price)}</p> : null}

                        {product.stock_warehouse > 0 && product.stock_warehouse <= 5 && <p className="text-sm text-mycia-red">Pochi prodotti rimasti.</p>}
                    </div>

                </div>

                {/* {product.suggested_drink != "" && <div className="px-3 py-1 text-sm bg-grey-400">
                    <p><span className="font-bold">Ottimo con: </span>{product.suggested_drink.name}</p>
                </div>} */}

                <Cta product={product} />

            </div>

        </div >
    );
}

export default Product;

import React, { useState, useLayoutEffect } from 'react';
import { useCart } from '../utils/contexts/cart-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import RadioLemonway from '../components/cart/RadioLemonway';
import RadioPaypal from '../components/cart/RadioPaypal';
import RadioSatispay from '../components/cart/RadioSatispay';
import formats from '../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';
import clsx from 'clsx';
import SumupWidget from '../components/cart/SumupWidget';
import PaypalWidget from '../components/cart/PaypalWidget';
import StripeWidget from '../components/cart/StripeWidget';

function PaymentsPage() {
    let storage = {};

    const { t } = useTranslation();
    const history = useHistory();

    const { restaurant } = useRestaurant();
    const { number, products, quantity, table, total, type, customers, customer, deliveryCost, order_note } = useCart();

    // const [intent, setIntent] = useState({ "provider": null, "url": null });
    const [payment, setPayment] = useState("");

    // const onChangeHandler = intent => setIntent(intent);
    // const onLemonwayHandler = () => window.location.href = intent.url;
    const onCloseHandler = () => history.goBack();

    function renderPayment() {
        if (payment === "") {
            return (
                <div className="pt-6">
                    <p className="text-2xl text-center py-3">Nessun pagamento selezionato</p>
                </div>
            )
        }
        if (payment === "paypal") {
            return (
                <div className="pt-6">
                    <p className="text-2xl text-center py-3">2) Completa il pagamento</p>
                    <PaypalWidget order={storage} />
                </div>
            )
        }
        if (payment === "sumup") {
            return (
                <div className="pt-6">
                    <p className="text-2xl text-center py-3">2) Completa il pagamento</p>
                    <SumupWidget order={storage} />
                </div>
            )
        }
        if (payment === "stripe") {
            return (
                <div className="pt-6">
                    <p className="text-2xl text-center py-3">2) Completa il pagamento</p>
                    <StripeWidget order={storage} />
                </div>
            )
        }
    }

    useLayoutEffect(() => {
        let orderTotal = total;

        let coupon = window.sessionStorage.getItem("coupon");
        if (coupon) {
            coupon = JSON.parse(coupon);
            orderTotal = formats.formatPercentageToPrice(coupon.discount, total);
            console.log({ discount: orderTotal })
        }

        if (type == "restaurant") {
            orderTotal = orderTotal + (restaurant.cover_charge * customers);
        } else {
            orderTotal = orderTotal;

            if (type == "delivery" && deliveryCost.cost_increase != null) orderTotal = orderTotal + deliveryCost.cost_increase;
        }
        let amount = orderTotal * 100;
        let commissions = (orderTotal * 0.02) * 100;

        storage["restaurant"] = restaurant;
        storage["order"] = {
            "amount": amount,
            "commissions": commissions,
            "customer": customer,
            "customers": customers,
            "number": number,
            "products": products,
            "quantity": quantity,
            "table": table,
            "timestamp": Date.now(),
            "total": orderTotal,
            "type": type,
            "order_note": order_note,
        };
        storage["transaction"] = {
            "id": "",
            "provider": payment,
        }

        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));
    }, [payment]);

    return (
        <IonPage>
            <IonContent>
                <section id="payments-page" className="relative pt-10 font-sans">
                    <button type="button" onClick={onCloseHandler} className="back-button flex items-center justify-center ml-2 mb-8 p-3 rounded-full shadow-lg bg-white">
                        <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                    </button>

                    <div className="w-full flex flex-col justify-center px-4 py-2 bg-white">
                        <p className="text-2xl text-center">1) Scegli un pagamento</p>
                        {formats.shouldDisplayPaymentsButtonV2(restaurant, "paypal", type) ? <button className={clsx(["block mt-4 py-2 rounded-full bg-grey-200", { "bg-mycia-green": "paypal" == payment }])} onClick={() => setPayment("paypal")}>Paga con Paypal</button> : null}
                        {formats.shouldDisplayPaymentsButtonV2(restaurant, "sumup", type) ? <button className={clsx(["block mt-4 py-2 rounded-full bg-grey-200", { "bg-mycia-green": "sumup" == payment }])} onClick={() => setPayment("sumup")}>Paga con Sumup</button> : null}
                        {formats.shouldDisplayPaymentsButtonV2(restaurant, "stripe", type) ? <button className={clsx(["block mt-4 py-2 rounded-full bg-grey-200", { "bg-mycia-green": "stripe" == payment }])} onClick={() => setPayment("stripe")}>Paga con Stripe</button> : null}
                    </div>

                    {renderPayment()}
                </section>
            </IonContent>
        </IonPage>
    );
}

export default PaymentsPage;

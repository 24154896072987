import React, { useEffect, useState } from 'react';
import Product from './Product';
import formats from '../../utils/formats';
import clsx from 'clsx';
import { useFilters } from '../../utils/contexts/filters-context';
import client from '../../logic/courses';
import forms from '../../utils/forms';

function Products(props) {
    let { shop, products, index, searchActive } = props;

    const { filters } = useFilters();

    const [megaFilter, setMegaFilter] = useState([]);

    let classnames = [
        "shop-products",
        { "grid": products.length > 0 },
        { "grid-cols-2": products.length > 0 },
        { "bg-white": formats.isEven(index) },
        { "bg-grey-200": !formats.isEven(index) }
    ];

    useEffect(() => {
        async function fetch() {
            if (shop) {
                const { allergies, foods, diets } = forms.parseFilters(filters);

                let allFilteredDishes;
                if (allergies || foods || diets) {
                    allFilteredDishes = await client.doGetFiltersV2("it", shop.id, allergies, foods, diets);
                    let aux = products;
                    let trovato = false;
                    let sort_index = 0;
                    for (let i = 0; i < products.length; i++) {
                        for (let j = 0; j < allFilteredDishes.dishes.length && trovato == false; j++) {
                            console.log(products[i].id + "==" + allFilteredDishes.dishes[j].id)
                            if (products[i].id == allFilteredDishes.dishes[j].id) {
                                trovato = true;
                                aux[i] = allFilteredDishes.dishes[j];
                                aux[i].sort_index = 0;
                            }
                        }
                        if (trovato == false) {
                            aux[i].user_suggestion = "Il prodotto non è adatto alle tue esigenze";
                            aux[i].other_user_suggestion = "Il prodotto non è adatto alle tue esigenze";
                            aux[i].icon = "images/icons/menu/ic_menu_no_icon.png";
                            aux[i].sort_index = sort_index;
                            sort_index++;
                        }
                        trovato = false;
                    }
                    aux.sort((a, b) => {
                        let keyA = a.sort_index;
                        let keyB = b.sort_index;
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                    setMegaFilter(aux);
                } else {
                    setMegaFilter(products);
                }
            }
        }

        fetch();
    }, [])

    useEffect(() => {
        console.log({ megaFilter });
    }, [megaFilter])

    return (
        <>
            {searchActive ?
                <div className={clsx(classnames)}>
                    {0 === megaFilter.length ?
                        (<p className="p-3">Nessun prodotto rispetta le tue preferenze.</p>) :
                        (megaFilter.map((product, i) => ((product.stock_warehouse == null || product.stock_warehouse > 0) ? <Product product={product} key={i} /> : null)))
                    }
                </div>
                :
                <div className={clsx(classnames)}>
                    {0 === products.length ?
                        (<p className="p-3">Nessun prodotto rispetta le tue preferenze.</p>) :
                        (products.map((product, i) => ((product.stock_warehouse == null || product.stock_warehouse > 0) ? <Product product={product} key={i} /> : null)))}
                </div>
            }
        </>
    );
}

export default Products;

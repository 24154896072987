import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IonModal } from '@ionic/react';
import DrinkModalContent from './DrinkModalContent';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useTracker } from '../../utils/contexts/tracker-context';

function DrinkModalTrigger(props) {
    const { drink, showDrinkModal, setShowDrinkModal, onDetailsOpenHandler } = props;

    const { t } = useTranslation();


    const onCloseHandler = () => setShowDrinkModal(false);

    return (
        <React.Fragment>
            <span onClick={onDetailsOpenHandler} className="drink-modal-trigger cursor-pointer py-1 underline text-sm text-blue-500">{t("drinkmodaltrigger.span_1")}</span>

            <IonModal isOpen={showDrinkModal} onDidDismiss={onCloseHandler}>
                <DrinkModalContent drink={drink} closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment >
    );
}

export default DrinkModalTrigger;

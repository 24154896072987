import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import Reviews from './Reviews';
import formats from '../../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';
import CouponModalTrigger from '../modals/CouponModalTrigger';
import features from '../../utils/features';
import client from '../../logic/cartSharing';
import sharer from '../../utils/sharer';

function CartSharingHost() {
    const history = useHistory();
    const location = useLocation();
    const { type, id } = useParams();

    const { products, quantity, total, add } = useCart();

    const { t } = useTranslation();

    const [coupon, setCoupon] = useState();
    const [newtotal, setNewTotal] = useState();
    const [couponSentinel, setCouponSentinel] = useState();
    const [activeShare, setActiveShare] = useState(false);
    const [shareId, setShareId] = useState();
    const [guestUsers, setGuestUsers] = useState([]);
    const [pusher] = useState(new window.Pusher('fd152ddc73707c6234c5', {
        cluster: 'eu'
    }));

    const onClickHandler = () => {
        if (type == "r") {
            history.push(`${location.pathname}/tables`);
        } else if (type == "d" || "t") {
            history.push(`${location.pathname}/type`);
        }
    }
    const onBackHandler = () => history.push(`/${type}/q/${id}/menu`);

    // useLayoutEffect(() => {
    //     async function pixelLog() {
    //         try {
    //             await window.fbq('track', 'ViewContent');
    //         } catch (e) {
    //             console.log({ pixelError: e })
    //         }
    //     }

    //     pixelLog();
    // }, [])

    useEffect(() => {
        function checkCoupon() {
            let coupon = sessionStorage.getItem("coupon");
            if (coupon) {
                coupon = JSON.parse(coupon);
                console.log(coupon);
                setCoupon(coupon);
                setNewTotal(formats.formatPercentageToPrice(coupon.discount, total));
            }
        }

        checkCoupon();
    }, [total, couponSentinel]);

    useEffect(() => {
        var channel = pusher.subscribe('cart-sharing');
        channel.bind('fetch-cart', function (data) {
            updateCart(data.token);
        });
    }, [])

    async function updateCart(shareId) {
        if (shareId) {
            const res = await client.doGet(shareId);
            if (res.order) {
                let allUserProducts = Array.prototype.concat.apply([], res.order.map(uP => uP.products));
                allUserProducts.map(product => {
                    console.log({ product });
                    add(product, product.quantity);
                })
                setGuestUsers(res.order.map((o => o.user)));
            }
        }
    };

    // Host -> Guest
    async function beginSharing() {
        let shareId = await client.doGetNewShareId();
        shareId = shareId.token;
        setShareId(shareId);
        console.log({ shareId });

        sharer.shareContent(`${type}/q/${id}/menu?shareid=${shareId}`, id, "Ecco il mio carrello condiviso, aggiungi all'ordine");

        setActiveShare(true);
    }

    return (
        <div className="cart-sharing-active pb-48">
            {features.toggler().includeCoupon() ? type != "r" ? <CouponModalTrigger setCouponSentinel={setCouponSentinel} /> : null : null}

            <div className="flex items-center border-b border-b-grey-200">
                <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>

                <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("cart.h2_1")}</h2>
            </div>

            <p className="p-4 px-10 text-center"> {t("cart.p_2")}</p>

            <Reviews products={products} />

            <div className="border-t-2 fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 pt-1 bg-white">

                {activeShare == false ?
                    <button type="button" onClick={beginSharing} className="block py-2 underline">Condividi ordine con un'altra persona</button>
                    :
                    // <button type="button" onClick={stopSharing} className="block py-2 underline">Termina condivisione</button>
                    <div>
                        <button type="button" onClick={() => sharer.shareContent(`${type}/q/${id}/menu?shareid=${shareId}`, id, "Ecco il mio carrello condiviso, aggiungi all'ordine")}
                            className="block py-2 underline">Condividi ancora</button>
                        <p className="block py-2 text-center flex flex-wrap items-center">Stai condividendo l'ordine con:
                        {guestUsers.map(u => <span className="m-1 p-1 border rounded bg-purple-200">{u}</span>)}</p>
                    </div>
                }

                {coupon && coupon.accepted == true ?
                    <p className="flex flex-col px-4 py-3">
                        <span>Totale: {formats.formatPrice(total)}</span>
                        <span>Valore coupon: {coupon.discount}%</span>
                        <span className="text-mycia-green">Totale scontato: <span className="font-bold">{formats.formatPrice(newtotal)}</span></span>
                    </p>
                    :
                    <p className="flex justify-between px-4 py-3"><span>{t("cart.p_1.span_1")}</span> <span className="font-bold">{formats.formatPrice(total)}</span></p>
                }

                {quantity ?
                    <button type="button" onClick={onClickHandler} className="block py-2 rounded-full bg-mycia-green">{t("cart.button_1")}</button>
                    :
                    <button type="button" onClick={onBackHandler} className="block py-2 rounded-full bg-grey-200">{t("cart.button_2")}</button>
                }

            </div>
        </div >
    );
}

export default CartSharingHost;

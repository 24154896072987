import React, { useEffect, useState } from 'react';
import client from '../../logic/courses';
import { IonSpinner } from '@ionic/react';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Products from './Products';
import dl from '../../utils/device-language';
import { useFilters } from '../../utils/contexts/filters-context';
import forms from '../../utils/forms';
import { useTranslation } from 'react-i18next';

function Row(props) {
    const { category, index } = props;
    const { filters } = useFilters();
    const { i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);

    async function onOpening() {
        // populate products with category products
        // products are loaded from categories logic
        console.log("on opening");

        let clientCategory;

        let language = process.env.REACT_APP_LANGUAGESELECTOR === "true" ? i18n.language : dl.getApiLanguage();
        const { allergies, foods, diets } = forms.parseFilters(filters);

        if (allergies || foods || diets) {
            clientCategory = await client.doGetFilters(language, category.id, allergies, foods, diets);
        } else {
            clientCategory = await client.doGet(language, category.id);
        }

        setProducts(clientCategory.dishes);
        setLoading(false);
    }

    useEffect(() => {
        if (open == true) {
            console.log({ openonuseffectfilters: open });
            setOpen(prevState => !prevState);
        }
    }, [filters])

    return (
        <Collapsible
            className="row"
            tabIndex={index}
            trigger={<CollapsibleTrigger index={index} trigger={category} setOpen={setOpen}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={category} opened setOpen={setOpen}></CollapsibleTrigger>}
            onTriggerOpening={onOpening}
            open={open}>
            <div>
                {isLoading ?
                    <IonSpinner name="dots" /> :
                    <Products products={products} index={index} />
                }
            </div>
        </Collapsible>
    );
}

export default Row;

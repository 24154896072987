import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useTracker } from '../../utils/contexts/tracker-context';
import Collapsible from 'react-collapsible';
import CollapsibleTrigger from '../UI/CollapsibleTrigger';
import Drinks from './Drinks';
import { useFilters } from '../../utils/contexts/filters-context';

function Beverage(props) {
    const { beverage, index } = props;

    const { type } = useParams();
    const { restaurant } = useRestaurant();
    const { trackDataFn } = useTracker();
    const { filters } = useFilters();

    const [open, setOpen] = useState(false);

    async function handleClick() {
        if ("mycia" !== process.env.REACT_APP_NAMESPACE) {
            await trackDataFn(restaurant.id, "categoryopen", {
                "type": type,
                "name": this.trigger.props.trigger.name,
                "is_campari": this.trigger.props.trigger.is_campari
            });
        }
        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (e) {
        //     console.log({ pixelError: e })
        // }
    }

    useEffect(() => {
        if (open == true) {
            setOpen(prevState => !prevState);
        }
    }, [filters])

    return (
        <Collapsible
            className="beverage"
            onOpen={handleClick}
            tabIndex={index}
            lazyRender={true}
            trigger={<CollapsibleTrigger index={index} trigger={beverage} setOpen={setOpen}></CollapsibleTrigger>}
            triggerWhenOpen={<CollapsibleTrigger index={index} trigger={beverage} opened setOpen={setOpen}></CollapsibleTrigger>}
            open={open}>
            <div>
                <Drinks drinks={beverage.drinks} index={index} />
            </div>
        </Collapsible>
    );
}

export default Beverage;

function getCoupon(couponId) {
    console.log(couponId)
    if (couponId == "cp123_A_5645fdgfdgtrT") {
        return {
            accepted: true,
            code: "cp123_A",
            discount: 1,
            name: "test 1",
        }
    }

    return {
        accepted: false,
        discount: "",
    }
}

export default {
    getCoupon,
}

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import { useCart } from "../../utils/contexts/cart-context";

function StripeWidgetContent(props) {
    const { order } = props;

    const { type, id } = useParams();
    const history = useHistory();
    const { type: orderType } = useCart();
    const { restaurant } = useRestaurant();

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [total, setTotal] = useState();

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        setTotal(order.order.total);

        let payment;
        if (orderType == "delivery") {
            payment = restaurant.orders.delivery.payment_methods.find(payment => payment.name === 'stripe');
        } else if (orderType == "takeaway") {
            payment = restaurant.orders.takeaway.payment_methods.find(payment => payment.name === 'stripe');
        } else if (orderType == "restaurant") {
            payment = restaurant.orders.tableservice.payment_methods.find(payment => payment.name === 'stripe');
        } else if (orderType == "shipment") {
            payment = restaurant.orders.shipment.payment_methods.find(payment => payment.name === 'stripe');
        }

        // Create PaymentIntent as soon as the page loads
        window
            .fetch("https://api-ristoratori.mycia.it/v2/stripe/checkout/fees/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "items": [
                        {
                            "amount": order.order.total * 100,
                            "currency": "eur",
                            "description": "test mcm",
                            "fee_amount": payment.fee,
                            "account_id": payment.account
                        }
                    ]
                })
            })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setClientSecret(data.clientSecret);
            });
    }, []);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "20px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            if (type == "t" || type == "d") {
                history.push(`/${type}/q/${id}/orders/awaitsms/?provider=stripe&transactionid=stripe_id`);
            }
            if (type == "r") {
                history.push(`/${type}/q/${id}/orders/success/?provider=stripe&transactionid=stripe_id`);
            }
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="p-2 bg-grey-100 rounded">
                <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            </div>
            <button
                disabled={processing || disabled || succeeded}
                id="submit"
                className="bg-purple-600 text-white rounded py-2 my-6 w-full"
            >
                <span id="button-text">
                    {processing ? (
                        <div className="spinner" id="spinner"></div>
                    ) : (
                            `Paga ora ${total}€`
                        )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded, see the result in your
        <a
                    href={`https://dashboard.stripe.com/test/payments`}
                >
                    {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
        </form>
    );
}

export default StripeWidgetContent;

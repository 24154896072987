import React, { useLayoutEffect, useState } from 'react';
import { IonContent, IonPage } from "@ionic/react";
import AllergensModalTrigger from '../components/modals/AllergensModalTrigger';
import features from '../utils/features';
import Fork from '../components/Fork';
import PoweredBy from '../components/UI/PoweredBy';
import RestaurantLoader from '../components/containers/RestaurantLoader';
import theme from '../utils/theme';
import { useParams } from 'react-router';

function MenuPage(props) {
    const [palette, setPalette] = useState("");

    const { id } = useParams();

    useLayoutEffect(() => {
        if (!process.env.REACT_APP_THEME) {
            theme.getTheme(id, setPalette);
        } else {
            setPalette(process.env.REACT_APP_THEME)
        }
    })

    return (
        <IonPage>
            <IonContent style={theme.getThemeIonComponents(palette).menupage}>
                <section id="menu-page" className="relative font-sans">
                    <RestaurantLoader component={Fork} {...props} />

                    <AllergensModalTrigger />

                    <PoweredBy />
                </section>
                {features.toggler().includeVersioning() && <p className="text-center text-sm">v.2.5.19</p>}
            </IonContent>
        </IonPage>
    );
}

export default MenuPage;

import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonSearchbar } from '@ionic/react';
import { useParams } from 'react-router';
import NewTabs from './UI/NewTabs';
import Bucket from './cart/Bucket';
import DeliveryPopupModalTrigger from './modals/DeliveryPopupModalTrigger';
import FilterModalTrigger from './modals/FilterModalTrigger';
import LanguageSelector from './LanguageSelector';
import assets from '../utils/assets';
import features from '../utils/features';
import formats from '../utils/formats';
import sharer from '../utils/sharer';
import { people, shareOutline } from 'ionicons/icons';
import TimeDiscountModalTrigger from './modals/TimeDiscountModalTrigger';
import Row from './shop/Row';
import Products from './shop/Products';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import SpoilerButton from './UI/SpoilerButton';
import CallRestaurantModalTrigger from './modals/CallRestaurantModalTrigger';
import SubscriptionModalTrigger from './modals/SubscriptionModalTrigger';

function Shop(props) {
    const { shop } = props;

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const { type, id } = useParams();
    const { t } = useTranslation();
    const { setSearch } = useRestaurant();

    useLayoutEffect(() => {
        let storage = window.localStorage.getItem("has_subscription");
        let obj = [{ id: "" }];
        if (storage) {
            obj = JSON.parse(storage);
            console.log(obj);
        }
        if (obj.filter(a => a.id == id).length > 0) {
            setShowSubscriptionModal(false);
        }

    }, [])

    console.log({ shoponmain: shop });
    return (
        <React.Fragment>
            <img src={shop.image_path} alt={t("menu.img_1")} className="w-full" />

            <div className="shop relative -mt-4 pt-4 pb-4 rounded-t-lg bg-white">
                <header className="px-4 pb-4 border-b border-b-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="pr-2">
                            <h1 className="font-medium text-3xl">{shop.name}</h1>
                        </div>
                        <div className="flex items-center">
                            {features.toggler().includeLanguageSelector() ? <LanguageSelector onMenu={true} /> :
                                <div>
                                    <button onClick={() => (sharer.shareContent(`${type}/q/${id}`, shop.name, t("menu.button_1")))} className="share button-native w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-mycia-red text-white">
                                        <IonIcon icon={shareOutline} size="medium" className="mb-1 text-xl"></IonIcon>
                                    </button>
                                </div>
                            }
                            {features.toggler().inludeCallRestaurant() ? shop.phone_number && type != "r" &&
                                <div className="mx-2">
                                    <CallRestaurantModalTrigger restaurant={shop} />
                                </div>
                                :
                                null
                            }
                            {features.toggler().includeSubscription() ?
                                showSubscriptionModal ?
                                    <button className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg bg-mycia-red text-white" onClick={() => setShowModal(true)}>
                                        <IonIcon icon={people} />
                                    </button>
                                    :
                                    null
                                :
                                null
                            }
                        </div>
                    </div>
                    <SpoilerButton content={shop.menu_description} />

                    {shop.has_filter &&
                        <div className="py-3 text-center">
                            <p className="text-base">Hai intolleranze o non ti piace qualcosa ?</p>
                            <FilterModalTrigger />
                        </div>
                    }

                </header>

                <IonSearchbar className="p-3" style={{ "--border-radius": "0.5rem", "--background": "#f2f2f2", "--box-shadow": "none" }} animated={true} placeholder="Cerca un prodotto" onIonChange={e => setSearch(e.detail.value)}></IonSearchbar>

                {shop.filtered_items ?
                    <div className="shop filtered-items">
                        <Products shop={shop} products={shop.filtered_items.dishes} index={0} searchActive />
                    </div>
                    :
                    <div className="shop courses">
                        {(shop.courses && shop.courses.length > 0) ?
                            shop.courses.map((category, i) => <Row category={category} index={i} key={i} />) : <p className="text-center">Nessun elemento disponibile</p>}
                    </div>
                }

                <Bucket />

                {features.toggler().includeSubscription() ? showSubscriptionModal ? <SubscriptionModalTrigger showModal={showModal} setShowModal={setShowModal} /> : null : null}

                {/* <DeliveryPopupModalTrigger /> */}

                {/* <TimeDiscountModalTrigger /> */}

            </div>
        </React.Fragment>
    );
}

export default Shop;
